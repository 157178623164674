@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
  overflow: hidden;
}
input[readonly],
input[type='checkbox'][readonly],
input[type='radio'][readonly],
label[readonly] {
  pointer-events: none;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  object-fit: cover;
}

.rdrInputRanges {
  display: none;
}

.react-datepicker__close-icon::after {
  background-color: #9ba3af;
}

.react-datepicker__close-icon {
  position: relative;
  left: -28px !important;
  color: cornsilk;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea,
select,
.ant-input {
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  border-radius: 6px;
  border-width: 1px;
}

::-webkit-scrollbar {
  width: 8px;
  position: absolute;
  background: white;
  right: 0;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0;
  background: white;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  height: 56px;
  border-radius: 8px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
  height: 56px;
  border-radius: 8px;
  background-clip: content-box;
}

.noscrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  position: absolute;
  right: 0;
}

/* Track */
.noscrollbar::-webkit-scrollbar-track {
  border-radius: 0;
  background: #f4f5f9;
}

/* Handle */
.noscrollbar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 0px;
  height: 200px;
  border: 2px solid #000;
}

/* Handle on hover */
.noscrollbar::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.loader {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #b6463a;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

select {
  display: block !important;
  width: 100% !important;
  font-size: 1em !important;
  padding: 0px 30px 0px 10px !important;
  font-family: inherit !important;
  /** for the dropdown indicator */
  appearance: none !important ;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") !important ;
  background-repeat: no-repeat !important;
  background-position: right 1rem center !important;
  background-size: 1em !important;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.big_loader {
  width: 64px;
  height: 64px;
}

.big_circular {
  animation: rotate 2s linear infinite;
  height: 64px;
  position: relative;
  width: 64px;
}

.big_path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #b6463a;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

.error-selection {
  border: 1px solid red;
  border-radius: 8px;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.sidebar::-webkit-scrollbar {
  width: 3px;
  position: absolute;
  background: white;
  right: 0;
  height: 10%;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  border-radius: 0;
  background: white;
  height: 10%;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: red !important;
  height: 56px;
  border-radius: 8px;
  background-clip: content-box;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 1);
  height: 56px;
  border-radius: 8px;
  background-clip: content-box;
}

.drawer-open {
  width: 256px !important;
  transition: width 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.drawer-open * {
  user-select: none;
}

.drawer-close {
  width: 0px !important;
  overflow: hidden;
  transition: width 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.cubic-transition {
  transition: padding 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.drawer_menu {
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.drawer_submenu_open {
  height: auto;
  opacity: 1;
}

.drawer_submenu_close {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.PhoneInputCountry {
  margin-right: unset !important;
  border: 1px solid rgb(229, 231, 235);
  padding: 5px;
  border-right: 0;
  border-radius: 6px 0 0 6px !important;
}

.PhoneInputInput {
  border-radius: 0 6px 6px 0 !important;
  flex: unset !important;
}

@media only screen and (max-width: 768px) {
  .event-mb-5 {
    margin-bottom: 5rem;
  }
}

* {
  box-sizing: border-box !important;
}
