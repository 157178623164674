.notification-bell {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 10px;
  line-height: 15px;
  font-size: 11px;
  font-weight: 700;
  background: #ff2b2b;
  height: 18px;
  width: 18px;
  color: white;
  top: -6px;
  border-radius: 100%;
  border: 1px solid white;
}

.notification-drawer-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: transparent;
}

.notification-drawer {
  border-radius: 8px;
  z-index: 100;
  position: absolute;
  background-color: white;
  left: 60px;
  top: 60px;
  width: 400px;
  overflow: hidden;
  height: 0px;
  transition: all 50ms ease-in-out;
}

.notification-drawer-open {
  height: 400px;
  max-height: 400px;
  box-shadow: 0px 20px 20px rgb(0 0 0 / 10%);
  border: 1px solid rgb(233, 235, 240);
}

* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

input:disabled {
  color:   #464141 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c0c0c0 !important;
  opacity: 1; /* Firefox */
}

.noscrollbar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
  right: 0;
}

/* Track */
.noscrollbar::-webkit-scrollbar-track {
  border-radius: 0 !important;
  background: #f4f5f9 !important;
}

/* Handle */
.noscrollbar::-webkit-scrollbar-thumb {
  background: #333 !important;
  border-radius: 0px !important;
  height: 200px !important;
  border: 2px solid #000 !important;
}

#react-qrcode-logo {
  border-radius: 20px;
}

/* Handle on hover */
.noscrollbar::-webkit-scrollbar-thumb:hover {
  background: #333 !important;
}

.tag {
  background: #3f51b5;
  padding: 8px 16px;
  margin-right: 12px;
  font-size: 14px;
  font-weight: medium;
  border-radius: 100px;
  color: white;
  white-space: nowrap;
}

.json-component {
  padding: 4px 8px;
  overflow: auto;
  background: #f4f5f9;
  border-radius: 6px;
  font-size: 13px;
  font-family: 'Courier New', Courier, monospace;
  max-width: 100%;
  max-height: 400px;
}

.root_container {
  max-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  80% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.box--gradient {
  display: flex;
  height: 100%;
  padding: 20px;
  position: relative;
  // width: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;

  &:after {
    content: '';
    top: 0;
    transform: translateX(100%) rotate(30deg);
    width: 300%;
    height: 300%;
    position: absolute;
    z-index: 1;
    animation: shine 3s infinite ease-in;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(128, 186, 232, 0) 100%);
  }
  &.silver {
    background: linear-gradient(to bottom, #bcc6cc, #eee, #bcc6cc);
  }
  &.gold {
    background: linear-gradient(to bottom, #c39738, #deb761, #c39738);
    &:after {
      animation-delay: 1s;
    }
  }
  &.platinum {
    background: linear-gradient(to bottom, #000, rgb(94, 94, 94), #000);
    &:after {
      animation-delay: 2s;
    }
  }

  &.diamond {
    background: linear-gradient(to bottom, #b9f2ff, #fff, #b9f2ff);
    &:after {
      animation-delay: 3s;
    }
  }
}

.infinite-scroll-component {
  background: #f4f5f9 !important;
}

@media screen and (max-width: 580px) {
  .error-message-full {
    height: calc(100vh - 72px);
  }
  .responsive-modal-container {
    border-radius: 0 !important;
    > div {
      height: 100vh !important;
      width: 100vw !important;
      min-height: 100vh !important;
      min-width: 100vw !important;
    }
  }

  .responsive-profile-article {
    height: 95vh !important;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .responsive-profile {
    width: 100% !important;
    height: 85% !important;
    border-radius: 0 !important;
    padding: 15px 0px !important;
  }

  .scanner-error {
    height: 100vh;
    border-radius: 0;
  }
  .scanner {
    width: 100vw !important;
    height: 100vw !important;
    > section {
      height: 100vw;
      display: flex;
      width: 100%;
      align-items: center;
      > div:first-child {
        height: 100vw !important;
      }
    }
  }
  .responsive-modal {
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

.c-input_text_overlay--large {
  font-size: 18px;
  height: 44px;
  line-height: 1.33333333;
  padding: 11px 12px 13px;
}

.c-input_text_overlay:before {
  color: rgba(var(--sk_foreground_max_solid, 97, 96, 97), 1);
  content: attr(data-overlay-prefix);
}

.p-signin_form__domain_input .c-input_text_overlay--large:after {
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  font-weight: 700;
}

.c-input_text_overlay:after {
  background: #0000;
  color: rgba(var(--sk_foreground_max_solid, 97, 96, 97), 1);
  content: attr(data-overlay-suffix);
  display: inline-block;
  font-weight: 400;
  margin-left: 1px;
}

.margin_bottom_100 {
  margin-bottom: 16px;
}
.full_width {
  width: 100%;
  max-width: 100%;
}
.c-input_text--large {
  font-size: 18px;
  height: 44px;
  line-height: 1.33333333;
  padding: 11px 12px 13px;
}
.c-input_text {
  --saf-0: rgba(var(--sk_primary_foreground, 29, 28, 29), 0.3);
  background-color: rgba(var(--sk_primary_background, 255, 255, 255), 1);
  border: 1px solid var(--saf-0);
  border-radius: 4px;
  // border: 1px solid #3f51b5;
  box-sizing: border-box;
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  font-size: 15px;
  height: 36px;
  line-height: 1.86666667;
  line-height: normal;
  margin: 0 0 20px;
  padding: 3px 12px 5px;
  transition: border 80ms ease-out, box-shadow 80ms ease-out;
  width: 100%;
}

.c-input_text_overlay {
  align-items: center;
  box-sizing: border-box;
  color: #0000;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  height: 36px;
  left: 0;
  line-height: 1.86666667;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 3px 12px 5px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

@primary-color: #fe5579;