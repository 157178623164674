.media-item {
  .play-button {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid white;
  }
  &:hover {
    .play-button {
      display: flex;
    }
  }
}

.media-item-reverse {
  .play-button {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid white;
  }
  &:hover {
    .play-button {
      display: none;
    }
  }
}

.body-editor {
  min-height: 200px;
  background-color: #fff;
  border: 1px solid #e4e7f5;
  padding: 12px;
  padding-top: 0px;
}

.email-item {
  background: #e4e7f5;
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
  padding-left: 12px;
  display: inline-block;
  font-size: 13px;
  border-radius: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.email-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal {
  img {
    width: 100%;
  }
}

.preview-modal-container {
  padding: 4px !important;
  height: auto !important;
}

.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.workspace_box {
  padding-top: 30px;
  padding-bottom: 20px;
}

// .tooltip {
//   display: none;
// }

.tooltip:hover + .tooltip-text {
  opacity: 1;
  // transition: opacity 0.5s;
  transform: translate(0, -5px);
}

@primary-color: #fe5579;